import axios from 'axios';
import store from './store'  // 导入 Vuex store

const api = axios.create({
baseURL: '/',
  timeout: 10000, // 请求超时设定
});

export default {
  login(data) {
    return api.post('/api/auth/login', data);
  },
  generateLink(data) {
    return api.post('/api/generate_subscription', data);
  },
  showSubscriptionUsage(data) {
    return api.post('/api/subscription_usage', data);
  },
  deleteSubscription(data) {
    return api.post('/api/delete_subscription', data);
  },
  getSettings(data) {
    return api.post('/api/get_settings', data);
  },
  saveSettings(data) {
    return api.post('/api/save_settings', data);
  },
  updateConfiguration: async () => {
    try {
      const token = store.state.token; // 从store获取token

      const response = await api.put('/api/configuration', {}, {
        headers: {
          'Authorization': `Bearer ${token}`,  // 在header中添加Authorization
          'Content-Type': 'application/json'
        }
      });

      // 成功更新后返回响应数据
      return response.data;

    } catch (error) {
      console.log(error);
      throw error;  // 继续抛出错误，以便调用处捕获处理
    }
  }
};