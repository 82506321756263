<template>
  <transition
    name="modal-fade"
    @leave="leave"
  >
  <div v-if="isOpen" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-20" @click="closeModal">
      <!-- 你的模态框内容 -->
      <div id="modal_window" class="p-6 rounded-lg shadow-lg w-3/4 lg:w-1/6 md:w-1/3 animate__animated animate__fadeInUp bg-orange-100" @click.stop>
        <h2 class="text-lg mb-4 font-bold text-left text-orange-900">
          <i class="weui--setting-filled text-md" style="vertical-align: bottom; line-height: 1;" />
          高级选项
        </h2>
        <!-- Close Icon -->
        <button @click="closeModal" class="absolute top-0 right-0 px-2.5 py-1.2 text-orange-100 bg-orange-700 hover:bg-orange-800 focus:outline-none rounded-tr-lg rounded-bl-lg">
          <span class="material-symbols--close-small-outline-rounded">close</span>
        </button>
        <div class="mb-4 text-orange-900">
          <div class="flex mb-3">
            <span class="font-bold">订阅有效时间</span>
            <span class="ml-auto">
              <input
                type="text"
                v-model="settingDataCopy.subscription_duration"
                data-input-counter
                class="flex-shrink-0 text-orange-900 border-0 bg-transparent text-sm font-normal focus:outline-none focus:ring-0 max-w-[2.5rem] text-center"
              />
              天&nbsp;&nbsp;&nbsp;
            </span>
          </div>

          <div class="flex mb-3">
            <span class="font-bold">配置更新周期</span>
            <span class="ml-auto">
              <input
                type="text"
                v-model="settingDataCopy.configation_update_interval"
                data-input-counter
                class="flex-shrink-0 text-orange-900 border-0 bg-transparent text-sm font-normal focus:outline-none focus:ring-0 max-w-[2.5rem] text-center"
              />
              小时
            </span>
          </div>

          <div class="flex mb-3">
            <span class="font-bold">
              更新时间
            </span>
            <span class="ml-auto text-sm">
              <span class="mr-1" id="configuration_update_time">{{ formattedUpdateTime }}</span>
              <button 
                @click="manuallyUpdate"
                id = "configuration_update_time_btn"
                class="material-symbols--refresh hover:text-orange-600 text-orange-900 text-sm font-semibold py-1 px-3 rounded"
                title="立即更新"
                >
              </button>
              &nbsp;&nbsp;
            </span>
          </div>

          <div class="flex mb-3">
            <span class="font-bold">
              清空订阅
            </span>
            <span class="ml-auto">
              <button 
                @click="deleteAllSubscription"
                id = "setting_delete_all_subscription"
                class=" bg-orange-700 hover:bg-orange-800 text-orange-100 text-sm font-semibold py-1 px-3 rounded">
                  确认删除
              </button>
            </span>
          </div>

        </div>

        <div class="flex justify-end space-x-2 mt-6">
          <button 
            @click="saveSettings"
            id="btn_save_settings"
            class="px-4 py-1.5 rounded bg-orange-700 hover:bg-orange-800 text-orange-100 font-semibold"
          >
            保存设置
          </button>
          <button 
            @click="closeModal" 
            class="px-4 py-1.5 rounded bg-orange-600 hover:bg-orange-700 text-orange-100 font-semibold"
          >
            关闭
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import 'animate.css';
import api from '../api'; // 导入封装好的API模块

export default {
  name: 'SettingModal',
  data() {
    return {
      settingDataCopy: { ...this.settingData }, // 创建 settingData 的局部副本
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    settingData: {
      type: Object,
      required: true,
    }
  },
  watch: {
    // 当 settingData 更新时，更新 settingDataCopy
    settingData: {
      handler(newVal) {
        this.settingDataCopy = { ...newVal };
      },
      deep: true,
    }
  },
  computed: {
    formattedUpdateTime() {
      const ts = this.settingDataCopy.configuration_update_time;
      if (ts == null){
        return "未更新";
      }
      if (ts >= 3600) {
        const hours = Math.floor(ts / 3600);
        return `${hours} 小时前`;
      } else if (ts >= 60) {
        const minutes = Math.floor(ts / 60);
        return `${minutes} 分钟前`;
      } else {
        return `${ts} 秒前`;
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    leave(el) {
      const modal_element = el.querySelector('#modal_window'); // 声明并获取 modal_element
      if (modal_element) {
        modal_element.classList.remove('animate__fadeInUp');
        modal_element.classList.add('animate__fadeOutDown');
      }
    },
    // manually update configuration
    async manuallyUpdate() {
      var button_element = document.getElementById("configuration_update_time_btn");
      button_element.disabled = true;
      button_element.classList.add('rotate');
      try {
        const response = await api.updateConfiguration();
        this.settingDataCopy.configuration_update_time = response.data;
        button_element.classList.remove('rotate');
      } catch (error) {
        console.log("更新失败")
      }
      button_element.disabled = false;
    },
    // delete all subscription
    async deleteAllSubscription() {
      var button_element = document.getElementById("setting_delete_all_subscription");
      button_element.disabled = true;
      button_element.innerHTML = `
<span>
删除中
<i aria-hidden="true" role="status" class="inline-block rounded-full animate-spin icon--loading align-middle" />
</span>`;
      var data = {
          token: this.$store.state.token,
      };
      const response = await api.deleteSubscription(data);
      
      if (response.data.result) {
          button_element.innerHTML = "订阅已清空";
      } else {
          button_element.innerHTML = "订阅删除失败";
          console.log(response.data.err);
      }
    },
    // 保存配置
    async saveSettings() {
      var button_element = document.getElementById("btn_save_settings");
      button_element.disabled = true;
      button_element.innerHTML = `
<span>
保存中
<i aria-hidden="true" role="status" class="inline-block rounded-full animate-spin icon--loading align-middle" />
</span>`;
      var data = {
          token: this.$store.state.token,
          subscription_duration: this.settingDataCopy.subscription_duration,
          configation_update_interval: this.settingDataCopy.configation_update_interval
      };
      const response = await api.saveSettings(data);
      
      if (response.data.result) {
          button_element.innerHTML = "保存成功";
      } else {
          button_element.innerHTML = "保存失败";
          console.log(response.data.err);
      }
    },
  }
};

</script>


<style scoped>
.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s;
}
.modal-fade-enter, .modal-fade-leave-to /* .modal-fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.fixed {
  z-index: 9999; /* 确保背景在模态框下 */
}
</style>
