// src/store.js

import { createStore } from 'vuex';
import api from './api'; // 引入你的 API 模块

const store = createStore({
  state: {
    token: null,
    user: null,
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setUser(state, user) {
      state.user = user;
    },
    clearAuth(state) {
      state.token = null;
      state.user = null;
    }
  },
  actions: {
    async login({ commit }, { username, password }) {
      try {
        // 使用 api 模块调用登录 API
        const response = await api.login({ username, password });

        if (response.status === 200) { // 如果请求成功
          const data = response.data;
          commit('setToken', data.token);
          commit('setUser', data.user);
          return { success: true };
        } else {
          // 若非200状态码，将错误消息返回
          return { success: false, error: response.data.message || '登录失败' };
        }
      } catch (error) {
        return { success: false, error: error.response.data.message || '登录失败' };
      }
    },
    logout({ commit }) {
      commit('clearAuth');
    }
  },
  getters: {
    isAuthenticated(state) {
      return !!state.token;
    },
    getToken(state) {
      return state.token;
    },
    getUser(state) {
      return state.user;
    }
  }
});

export default store;