import { createRouter, createWebHistory } from 'vue-router';
import LandingPage from '@/components/LandingPage.vue';
import DashboardPage from '@/components/DashboardPage.vue';

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage
  },
  {
    path: '/dashboard',
    name: 'DashboardPage',
    component: DashboardPage
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

// Set the document title dynamically based on the route's meta title
router.beforeEach((to, from, next) => {
  document.title = '节点订阅服务';
  next();
});

export default router;

